import {
  getBindingAndReactivePathsOfAction,
  getDataTreeActionConfigPath,
} from 'entities/Action/actionProperties';
import { DataTreeAction, ENTITY_TYPE } from 'entities/DataTree/dataTreeFactory';
import { get, isEmpty } from 'lodash';
import { ActionData } from 'reducers/entityReducers/actionsReducer';
import { DependencyMap, DynamicPath } from 'utils/DynamicBindingUtils';

export const generateDataTreeAction = (
  action: ActionData,
  editorConfig: any[],
  dependencyConfig: DependencyMap = {}
): DataTreeAction => {
  let dynamicBindingPathList: DynamicPath[] = [];

  // update paths
  if (
    action.config.dynamicBindingPathList &&
    action.config.dynamicBindingPathList.length
  ) {
    dynamicBindingPathList = action.config.dynamicBindingPathList.map((d) => ({
      ...d,
      key: d.key === 'datasourceUrl' ? d.key : `config.${d.key}`,
    }));
  }

  const datasourceUrl = get(
    action.config,
    'datasource.datasourceConfiguration.url',
    ''
  );

  const dependencyMap: DependencyMap = {};
  Object.entries(dependencyConfig).forEach(([dependent, dependencies]) => {
    dependencyMap[getDataTreeActionConfigPath(dependent)] = dependencies.map(
      getDataTreeActionConfigPath
    );
  });

  const { bindingPaths, reactivePaths } = getBindingAndReactivePathsOfAction(
    action.config,
    editorConfig
  );

  let data;
  if (action.data && !isEmpty(action.data.body)) {
    data = action.data.body;
  } else {
    data = undefined;
  }
  return {
    run: {},
    clear: {},
    actionId: action.config.id,
    name: action.config.name,
    pluginId: action.config.pluginId,
    pluginType: action.config.pluginType,
    config: action.config.actionConfiguration,
    dynamicBindingPathList,
    data,
    total: action.data
      ? get(action.data, 'total', get(action, 'data.body.length', 0))
      : 0,
    tableComments: get(action.data, 'tableComments', {}),
    responseMeta: {
      statusCode: action.data?.statusCode,
      isExecutionSuccess: action.data?.isExecutionSuccess || false,
      headers: action.data?.headers ?? get(action, 'data.selectFieldNames', []),
    },
    ENTITY_TYPE: ENTITY_TYPE.ACTION,
    isLoading: action.isLoading,
    bindingPaths,
    reactivePaths,
    dependencyMap,
    logBlackList: {},
    datasourceUrl,
  };
};

export default {
  INPUT_TEXT: 'INPUT_TEXT',
  FIXED_KEY_INPUT: 'FIXED_KEY_INPUT',
  DROP_DOWN: 'DROP_DOWN',
  SWITCH: 'SWITCH',
  KEYVALUE_ARRAY: 'KEYVALUE_ARRAY',
  FILE_PICKER: 'FILE_PICKER',
  KEY_VAL_INPUT: 'KEY_VAL_INPUT',
  QUERY_DYNAMIC_TEXT: 'QUERY_DYNAMIC_TEXT',
  QUERY_DYNAMIC_INPUT_TEXT: 'QUERY_DYNAMIC_INPUT_TEXT',
  CHECKBOX: 'CHECKBOX',
  NUMBER_INPUT: 'NUMBER_INPUT',
  ARRAY_FIELD: 'ARRAY_FIELD',
  WHERE_CLAUSE: 'WHERE_CLAUSE',
  ENTITY_SELECTOR: 'ENTITY_SELECTOR',
  PAGINATION: 'PAGINATION',
  SORTING: 'SORTING',
  PROJECTION: 'PROJECTION',
  SLIDER: 'SLIDER',
  RADIO: 'RADIO',
  /**
   * NOTE: If you have created a new control, you should add the name to this object and registry it in the FormControlRegistry.txs file.
   *
   */
  OPTION_INPUT: 'OPTION_INPUT',
  KEY_VAL_DROPDOWN_INPUT: 'KEY_VAL_DROPDOWN_INPUT',
};

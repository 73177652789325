export default [
  {
    sectionName: '',
    id: 1,
    children: [
      {
        label: '当页面加载完成后运行',
        configProperty: 'executeOnLoad',
        controlType: 'SWITCH',
        info: '每次页面加载时都会刷新数据',
      },
      {
        label: '在运行查询之前请求确认',
        configProperty: 'confirmBeforeExecute',
        controlType: 'SWITCH',
        info: '每次刷新数据前都要求用户确认',
      },
      // {
      //   label: "Cache response",
      //   configProperty: "shouldCacheResponse",
      //   controlType: "SWITCH",
      // },
      // {
      //   label: "Cache timeout (in milliseconds)",
      //   configProperty: "cacheTimeout",
      //   controlType: "INPUT_TEXT",
      //   dataType: "NUMBER",
      // },
      {
        label: '设置查询超时（以毫秒为单位）',
        info: 'Maximum time after which the query will return',
        configProperty: 'actionConfiguration.timeoutInMillisecond',
        controlType: 'INPUT_TEXT',
        dataType: 'NUMBER',
      },
    ],
  },
];

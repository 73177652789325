export default [
  {
    sectionName: '',
    id: 1,
    children: [
      {
        label: '在页面加载时运行 API',
        configProperty: 'executeOnLoad',
        controlType: 'CHECKBOX',
        info: '每次页面加载时都会刷新数据',
      },
      {
        label: '运行 API 前请求确认',
        configProperty: 'confirmBeforeExecute',
        controlType: 'CHECKBOX',
        info: '每次刷新数据前都要求用户确认',
      },
      {
        label: '编码查询参数',
        configProperty: 'actionConfiguration.encodeParamsToggle',
        controlType: 'CHECKBOX',
        info:
          '编码查询参数 适用于所有 API。当 Content-Type 标头设置为 x-www-form-encoded 时，还要对表单正文进行编码',
      },
      {
        label: '智能 JSON 替换',
        configProperty: 'actionConfiguration.pluginSpecifiedTemplates[0].value',
        controlType: 'CHECKBOX',
        info:
          '打开此属性可通过智能添加/删除引号修复 API 正文中绑定的 JSON 替换，并减少开发人员错误',
        initialValue: true,
      },
      // {
      //   label: "Cache response",
      //   configProperty: "shouldCacheResponse",
      //   controlType: "SWITCH",
      // },
      // {
      //   label: "Cache timeout (in milliseconds)",
      //   configProperty: "cacheTimeout",
      //   controlType: "INPUT_TEXT",
      //   dataType: "NUMBER",
      // },
      {
        label: 'API 超时（以毫秒为单位）',
        info: 'API 返回的最长时间',
        controlType: 'INPUT_TEXT',
        configProperty: 'actionConfiguration.timeoutInMillisecond',
        dataType: 'NUMBER',
      },
    ],
  },
];

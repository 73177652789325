/* eslint-disable no-console */
import { DataTree } from 'entities/DataTree/dataTreeFactory';
import {
  EvaluationError,
  extraLibraries,
  PropertyEvaluationErrorType,
  unsafeFunctionForEval,
} from 'utils/DynamicBindingUtils';
import unescapeJS from 'unescape-js';
import { Severity } from 'entities/AppsmithConsole';
import { enhanceDataTreeWithFunctions } from './Actions';
import { Dictionary, get, isEmpty, mapValues, merge, values } from 'lodash';
import { getLintingErrors } from 'workers/lint';
import { completePromise } from 'workers/PromisifyAction';
import { ActionDescription } from 'entities/DataTree/actionTriggers';

export type EvalResult = {
  result: any;
  errors: EvaluationError[];
  triggers?: ActionDescription[];
  script?: string;
  scriptType?: EvaluationScriptType;
  globals?: Dictionary<boolean>;
};

export enum EvaluationScriptType {
  EXPRESSION = 'EXPRESSION',
  ANONYMOUS_FUNCTION = 'ANONYMOUS_FUNCTION',
  ASYNC_ANONYMOUS_FUNCTION = 'ASYNC_ANONYMOUS_FUNCTION',
  TRIGGERS = 'TRIGGERS',
}

export const ScriptTemplate = '<<string>>';

//🐑 不同类型js 执行方式
export const EvaluationScripts: Record<EvaluationScriptType, string> = {
  [EvaluationScriptType.EXPRESSION]: `
  function closedFunction () {
    const result = ${ScriptTemplate}
    return result;
  }
  closedFunction.call(THIS_CONTEXT)
  `,
  [EvaluationScriptType.ANONYMOUS_FUNCTION]: `
  function callback (script) {
    const userFunction = script;
    const result = userFunction?.apply(THIS_CONTEXT, ARGUMENTS);
    return result;
  }
  callback(${ScriptTemplate})
  `,
  [EvaluationScriptType.ASYNC_ANONYMOUS_FUNCTION]: `
  async function callback (script) {
    const userFunction = script;
    const result = await userFunction?.apply(THIS_CONTEXT, ARGUMENTS);
    return result;
  }
  callback(${ScriptTemplate})
  `,
  [EvaluationScriptType.TRIGGERS]: `
  async function closedFunction () {
    const result = await ${ScriptTemplate};
    return result;
  }
  closedFunction.call(THIS_CONTEXT);
  `,
};

const getScriptType = (
  evalArgumentsExist = false,
  isTriggerBased = false
): EvaluationScriptType => {
  let scriptType = EvaluationScriptType.EXPRESSION;
  if (evalArgumentsExist && isTriggerBased) {
    scriptType = EvaluationScriptType.ASYNC_ANONYMOUS_FUNCTION;
  } else if (evalArgumentsExist && !isTriggerBased) {
    scriptType = EvaluationScriptType.ANONYMOUS_FUNCTION;
  } else if (!evalArgumentsExist && isTriggerBased) {
    scriptType = EvaluationScriptType.TRIGGERS;
  }
  return scriptType;
};

export const getScriptToEval = (
  userScript: string,
  type: EvaluationScriptType
): string => {
  // Using replace here would break scripts with replacement patterns (ex: $&, $$)
  const buffer = EvaluationScripts[type].split(ScriptTemplate);
  return `${buffer[0]}${userScript}${buffer[1]}`;
};

export function setupEvaluationEnvironment(metaData = {}) {
  ///// Adding extra libraries separately
  extraLibraries.forEach((library) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: No types available
    self[library.accessor] = library.lib;
  });

  ///// Remove all unsafe functions
  unsafeFunctionForEval.forEach((func) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: No types available
    self[func] = undefined;
  });

  extraLibraries.forEach((library) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: No types available
    self[library.accessor] = library.lib;
  });
  merge(self, metaData);
}

const beginsWithLineBreakRegex = /^\s+|\s+$/;

export const createGlobalData = (
  dataTree: DataTree,
  resolvedFunctions: Record<string, any>,
  isTriggerBased: boolean,
  context?: EvaluateContext,
  evalArguments?: Array<any>
) => {
  const GLOBAL_DATA: Record<string, any> = {};
  ///// Adding callback data
  GLOBAL_DATA.ARGUMENTS = evalArguments;
  //// Adding contextual data not part of data tree
  GLOBAL_DATA.THIS_CONTEXT = {};
  if (context) {
    if (context.thisContext) {
      GLOBAL_DATA.THIS_CONTEXT = context.thisContext;
    }
    if (context.globalContext) {
      Object.entries(context.globalContext).forEach(([key, value]) => {
        GLOBAL_DATA[key] = value;
      });
    }
  }
  if (isTriggerBased) {
    //// Add internal functions to dataTree;
    const dataTreeWithFunctions = enhanceDataTreeWithFunctions(
      dataTree,
      context?.requestId
    );
    ///// Adding Data tree with functions
    Object.keys(dataTreeWithFunctions).forEach((datum) => {
      GLOBAL_DATA[datum] = dataTreeWithFunctions[datum];
    });
  } else {
    Object.keys(dataTree).forEach((datum) => {
      GLOBAL_DATA[datum] = dataTree[datum];
    });
  }
  if (!isEmpty(resolvedFunctions)) {
    Object.keys(resolvedFunctions).forEach((datum: any) => {
      const resolvedObject = resolvedFunctions[datum];
      Object.keys(resolvedObject).forEach((key: any) => {
        const dataTreeKey = GLOBAL_DATA[datum];
        if (dataTreeKey) {
          const data = dataTreeKey[key]?.data;
          //do not remove we will be investigating this
          //const isAsync = dataTreeKey?.meta[key]?.isAsync || false;
          //const confirmBeforeExecute =
          dataTreeKey?.meta[key]?.confirmBeforeExecute || false;
          dataTreeKey[key] = resolvedObject[key];
          // if (isAsync && confirmBeforeExecute) {
          //   dataTreeKey[key] = confirmationPromise.bind(
          //     {},
          //     context?.requestId,
          //     resolvedObject[key],
          //     dataTreeKey.name + "." + key,
          //   );
          // } else {
          //   dataTreeKey[key] = resolvedObject[key];
          // }
          if (!!data) {
            dataTreeKey[key]['data'] = data;
          }
        }
      });
    });
  }
  return GLOBAL_DATA;
};

export function sanitizeScript(js: string) {
  // We remove any line breaks from the beginning of the script because that
  // makes the final function invalid. We also unescape any escaped characters
  // so that eval can happen
  const trimmedJS = js.replace(beginsWithLineBreakRegex, '');
  return self.evaluationVersion > 1 ? trimmedJS : unescapeJS(trimmedJS);
}

/** Define a context just for this script
 * thisContext will define it on the `this`
 * globalContext will define it globally
 * requestId is used for completing promises
 */
export type EvaluateContext = {
  thisContext?: Record<string, any>;
  globalContext?: Record<string, any>;
  requestId?: string;
};

//TODO optimize
export const getUserScriptToEvaluate = (
  userScript: string,
  GLOBAL_DATA: Record<string, unknown>,
  isTriggerBased: boolean,
  evalArguments?: Array<any>,
  needLint = false
) => {
  const unescapedJS = sanitizeScript(userScript);
  // If nothing is present to evaluate, return instead of linting
  if (!unescapedJS.length) {
    return {
      lintErrors: [],
      script: '',
    };
  }
  const scriptType = getScriptType(!!evalArguments, isTriggerBased);
  const script = getScriptToEval(unescapedJS, scriptType);
  const scriptToLint = script;
  // We are linting original js binding,
  // This will make sure that the character count is not messed up when we do unescapejs
  //Todo:⏰
  if (!needLint) {
    return {
      script,
      lintErrors: [],
      scriptType,
      globals: mapValues(GLOBAL_DATA, () => true),
    };
  }
  const lintErrors =
    'EDIT' === get(self, 'mode')
      ? getLintingErrors(
          scriptToLint,
          mapValues(GLOBAL_DATA, () => true),
          userScript,
          scriptType
        )
      : [];
  return {
    script,
    lintErrors: lintErrors,
    scriptType,
    globals: mapValues(GLOBAL_DATA, () => true),
  };
};

/**
 * case to use
 * 1. fn:saveResolvedFunctionsAndJSUpdates 保存js的时候,解析字符串为Object
 * 2. fn:evaluateDynamicBoundValue 解析{{}}
 */

export default function evaluateSync(
  userScript: string,
  dataTree: DataTree,
  resolvedFunctions: Record<string, any>,
  isJSCollection: boolean,
  context?: EvaluateContext,
  evalArguments?: Array<any>
): EvalResult {
  return (function () {
    let errors: EvaluationError[] = [];
    let result;
    /**** Setting the eval context ****/
    const GLOBAL_DATA: Record<string, any> = createGlobalData(
      dataTree,
      resolvedFunctions,
      isJSCollection,
      context,
      evalArguments
    );
    GLOBAL_DATA.ALLOW_ASYNC = false;
    //⏰ lint
    const { globals, lintErrors, script, scriptType } = getUserScriptToEvaluate(
      userScript,
      GLOBAL_DATA,
      false,
      evalArguments
    );
    // If nothing is present to evaluate, return instead of evaluating
    if (!script.length) {
      return {
        errors: [],
        result: undefined,
        triggers: [],
      };
    }
    errors = lintErrors || [];

    // Set it to self so that the eval function can have access to it
    // as global data. This is what enables access all appsmith
    // entity properties from the global context
    for (const entity in GLOBAL_DATA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No types available
      self[entity] = GLOBAL_DATA[entity];
    }

    try {
      // console.log(THIS_CONTEXT);
      result = eval(script);
    } catch (e) {
      const errorMessage = `${e.name}: ${e.message}`;

      errors.push({
        errorMessage: errorMessage,
        severity: Severity.ERROR,
        raw: script,
        errorType: PropertyEvaluationErrorType.PARSE,
        originalBinding: userScript,
      });
    } finally {
      for (const entity in GLOBAL_DATA) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: No types available
        delete self[entity];
      }
    }

    return { result, errors, script, scriptType, globals };
  })();
}
/**
 * case to used
 * 1. evaluateTriggers
 * 2. EVAL_WORKER_ACTIONS.EVAL_EXPRESSION && isTrigger 基本不可能
 */
export async function evaluateAsync(
  userScript: string,
  dataTree: DataTree,
  requestId: string,
  resolvedFunctions: Record<string, any>,
  context?: EvaluateContext,
  evalArguments?: Array<any>
) {
  return (async function () {
    const errors: EvaluationError[] = [];
    let result;
    /**** Setting the eval context ****/
    const GLOBAL_DATA: Record<string, any> = createGlobalData(
      dataTree,
      resolvedFunctions,
      true,
      { ...context, requestId },
      evalArguments
    );
    //! 第8步
    const { script } = getUserScriptToEvaluate(
      userScript,
      GLOBAL_DATA,
      true,
      evalArguments
    );
    // debugger
    GLOBAL_DATA.ALLOW_ASYNC = true;
    // Set it to self so that the eval function can have access to it
    // as global data. This is what enables access all appsmith
    // entity properties from the global context
    Object.keys(GLOBAL_DATA).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No types available
      self[key] = GLOBAL_DATA[key];
    });

    try {
      // console.log(
      //   userScript,
      //   dataTree,
      //   requestId,
      //   resolvedFunctions,
      //   context,
      //   evalArguments,
      //   'requestId'
      // );
      //! 第9步
      result = await eval(script);
    } catch (error) {
      const errorMessage = `错误提示: ${error.message}`;
      errors.push({
        errorMessage: errorMessage,
        severity: Severity.ERROR,
        raw: script,
        errorType: PropertyEvaluationErrorType.PARSE,
        originalBinding: userScript,
      });
    } finally {
      completePromise(requestId, {
        result,
        errors,
        triggers: Array.from(self.TRIGGER_COLLECTOR),
      });
      for (const entity in GLOBAL_DATA) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: No types available
        delete self[entity];
      }
    }
  })();
}

export function isFunctionAsync(
  userFunction: unknown,
  dataTree: DataTree,
  resolvedFunctions: Record<string, any>,
  logs: unknown[] = []
) {
  return userFunction.toString().includes('async');
}

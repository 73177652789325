import localforage from 'localforage';

import { LRUCache } from './lru';

const dbStoreData: any & Storage = localforage.createInstance({
  name: 'DBData',
  driver: localforage.INDEXEDDB, // force IndexedDB
  description: 'Storing somethings into IndexedDB',
});

const lruCache = new LRUCache();

class DBStore {
  db: LocalForage;
  isReady: boolean;

  constructor() {
    this.db = dbStoreData;
    this.isReady = false;

    this.checkDB();
  }

  checkDB() {
    this.db.ready((err) => {
      if (err) {
        console.log(err);
        return;
      }

      this.isReady = true;
    });
  }

  async setItem(key: string, value: unknown) {
    try {
      await this.db.setItem(key, value);
      lruCache.write(key, value);
    } catch (e) {
      console.log(e);
    }
  }

  async getItem(key: string, defaultValue?: unknown) {
    try {
      const cachedValue = lruCache.read(key);

      if (cachedValue) {
        return cachedValue;
      }

      return (await this.db.getItem(key)) || defaultValue;
    } catch (e) {
      console.log(e);
    }
  }

  async removeItem(key: string) {
    try {
      await this.db.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }

  async queryStoreKeys() {
    try {
      return await this.db.keys();
    } catch (e) {
      console.log(e);
    }
  }

  async clear() {
    try {
      await this.db.clear();
    } catch (e) {
      console.log(e);
    }
  }
}

const dbStore = new DBStore();

export default dbStore;

import { nth } from 'lodash';

const flag =
  (process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_BY_JADEN === 'true') ||
  location.search.includes('zw-debugger');

export function time(mark: string) {
  flag && performance.mark(mark + '-start');
}

export function timeEnd(mark: string) {
  if (flag) {
    performance.mark(mark + '-end');
    // 测量两个不同的标志。
    performance.measure(`${mark}`, mark + '-start', mark + '-end');
    logmEasure(mark);
  }
}

function logmEasure(mark: string) {
  if (flag) {
    const measures = performance.getEntriesByName(mark);
    const measure = nth(measures, -1);
    // set Leave color
    if (measure.duration > 100) {
      console.log(
        '%c' + measure.name + ': ' + measure.duration + '📏',
        'color: red; font-weight: bold;'
      );
    } else if (measure.duration > 50) {
      console.log(
        '%c' + measure.name + ': ' + measure.duration + '📏',
        'color: brown; font-weight: bold;'
      );
    } else if (measure.duration > 30) {
      console.log(
        '%c' + measure.name + ': ' + measure.duration + '📏',
        'color: orange; font-weight: bold;'
      );
    } else {
      console.log(
        '%c' + measure.name + ': ' + measure.duration + '📏',
        'color: green; font-weight: bold;'
      );
    }
  }
}

export default [
  {
    sectionName: '',
    id: 1,
    children: [
      {
        label: '在页面加载时运行 API',
        configProperty: 'executeOnLoad',
        controlType: 'SWITCH',
        info: '每次页面加载时都会刷新数据',
      },
      {
        label: '运行 API 前请求确认',
        configProperty: 'confirmBeforeExecute',
        controlType: 'SWITCH',
        info: '每次刷新数据前都要求用户确认',
      },
      {
        label: 'API 超时（以毫秒为单位）',
        info: 'API 返回的最长时间',
        configProperty: 'actionConfiguration.timeoutInMillisecond',
        controlType: 'INPUT_TEXT',
        dataType: 'NUMBER',
      },
    ],
  },
];
